<template>
  <div id="approval-history">
    <div class="title">Doktor Not Geçmişi</div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Anasayfa</el-breadcrumb-item>
        <el-breadcrumb-item>Doktor Not Geçmişi</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="table-general">
      <div class="search-status">
        <div class="status">
          <div class="show">Göster</div>
          <el-select class="first" v-model="filter.PageSize" placeholder="Seçiniz...">
            <el-option v-for="(item, index) in pagesize" :key="index" :label="item" :value="item"> </el-option>
          </el-select>
          <el-select v-model="filter.Status" placeholder="Seçiniz..." v-if="getEnums != null" clearable @clear="filter.Status = null">
            <el-option v-for="item in getEnums.find((x) => x.Title == 'DoctorNoteApproval').Enums" :key="item.Key" :label="item.DisplayName" :value="item.Key"> </el-option>
          </el-select>
        </div>

        <div class="search">
          <el-input style="width: 290px" placeholder="Arama..." v-model="filter.SearchTerm" clearable>
            <i slot="prefix" class="el-input__icon el-icon-search" style="color: #8697a8; font-weight: bold"></i>
          </el-input>
        </div>
      </div>
      <div class="all-data">
        <div class="list-table">
          <!--<el-table :data="noteList">
            <el-table-column prop="AppointmentNo" label="GÖRÜŞME NO" stripe width="160">
              <template slot-scope="scope"> #{{ scope.row.AppointmentNo }} </template>
            </el-table-column>
            <el-table-column label="DOKTOR ADI" prop="DoctorUser.Title" stripe width="220"> </el-table-column>
            //<el-table-column prop="PatientDiagnosis.DoctorNotes" label="ESKİ DOKTOR NOTU"> </el-table-column>//
            <el-table-column prop="NoteRequest.NewDoctorNote" label="YENİ DOKTOR NOTU"> </el-table-column>
            <el-table-column label="DURUM" width="200">
              <template slot-scope="scope">
                <div class="color-dot" v-if="scope.row.NoteRequest.Status != null || scope.row.NoteRequest.Status != undefined">
                  <div class="dot" :class="{ yellow: scope.row.NoteRequest.Status == 1, green: scope.row.NoteRequest.Status == 2, red: scope.row.NoteRequest.Status == 3 }"></div>
                  {{ getEnumsDisplay("DoctorNoteApproval", scope.row.NoteRequest.Status) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="TALEP TARİHİ" stripe width="180">
              <template slot-scope="scope">
                <div class="dates">
                  <div>
                    {{ $moment(scope.row.NoteRequest.RequestTime).format("DD.MM.YYYY") }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="" stripe width="250">
              <template slot-scope="scope">
                <div class="table-button">
                  <el-button type="success" :disabled="scope.row.NoteRequest.Status != 1" @click="approvalNote(true, scope.row.AppointmentNo)">Onayla</el-button>
                  <el-button type="danger" :disabled="scope.row.NoteRequest.Status != 1" @click="approvalNote(false, scope.row.AppointmentNo)">Reddet</el-button>
                </div>
              </template>
            </el-table-column>
            <template slot="empty">
              <div class="lottie-container">
                <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
                <div class="no-data-text">Kayıt bulunamadı.</div>
                <div class="no-data-text">Herhangi bir doktor not geçmişi bulunamamıştır.</div>
              </div>
            </template>
          </el-table>-->
          <el-table :data="noteList" :cell-style="{ padding: '0', height: '70px' }">
            <el-table-column prop="AppointmentNo" label="GÖRÜŞME NO" stripe width="160">
              <template slot-scope="scope"> #{{ scope.row.AppointmentNo }} </template>
            </el-table-column>
            <el-table-column label="DOKTOR ADI" prop="DoctorUser.Title" stripe width="250"> </el-table-column>
            <el-table-column prop="PatientDiagnosis.DoctorNotes" label="YENİ DOKTOR NOTU"> </el-table-column>
            <!--<el-table-column prop="NoteRequest.NewDoctorNote" label="YENİ DOKTOR NOTU"> </el-table-column>-->
            <el-table-column label="DEĞİŞTİRME TARİHİ" stripe width="200">
              <template slot-scope="scope">
                <div class="dates">
                  <div>
                    {{ $moment(scope.row.NoteRequest.RequestTime).format("DD.MM.YYYY") }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <template slot="empty">
              <div class="lottie-container">
                <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
                <div class="no-data-text">Kayıt bulunamadı.</div>
                <div class="no-data-text">Herhangi bir doktor not geçmişi bulunamamıştır.</div>
              </div>
            </template>
          </el-table>
        </div>
      </div>
      <div class="pagination">
        <div class="info">{{ filter.PageSize * filter.Page - filter.PageSize }}-{{ filter.PageSize * filter.Page > result.TotalCount ? result.TotalCount : filter.PageSize * filter.Page }} {{ $localization('web-pagination-info-text')
        }} {{ result.TotalCount }}</div>
        <el-pagination background :current-page.sync="filter.Page" :page-size="filter.PageSize" layout="prev, pager, next" :total="result.TotalCount"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pagesize: [10, 20, 30, 40],
      AdminApproval: false,
      AppointmentNo: null,
    };
  },
  async beforeMount() {
    await this.$store.dispatch("getNoteList");
  },
  methods: {
    async approvalNote(bool, no) {
      var payload = { AppointmentNo: no, AdminApproval: bool };
      var res = await this.$client.post("/Admin/ApproveDoctorRequest", payload);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        await this.$store.dispatch("getNoteList");
      }
    },
  },
  computed: {
    filter() {
      return this.$store.getters.getNoteFilter;
    },
    noteList() {
      return this.$store.getters.getNoteList;
    },
    validation() {
      return this.$store.getters.getNoteValidation;
    },
    result() {
      return this.$store.getters.getNoteResult;
    },
    getEnums() {
      return this.$store.state.auth.enums;
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.$store.dispatch("getNoteList");
      },
    },
  },
};
</script>

<style lang="less">
#approval-history {
  padding: 42px 30px;

  .danger-btn-confirm {
    background-color: #234A98;
  }

  .title {
    font-size: 30px;
    color: #44566c;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 22px;

    span {
      color: #8697a8;
    }
  }

  .breadcrumb {
    .buttons-group {
      display: flex;
      gap: 24px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #eaedf0;
        border: none;

        i {
          background: #44566c;
        }
      }
    }
  }

  .table-general {
    display: flex;
    flex-direction: column;

    .el-table {

      .el-table__body-wrapper {
        .el-table__body {
          .el-table__row {
            .el-table__cell {
              .table-button {
                display: flex;

                .is-disabled {
                  background-color: #dce2e6;
                  border-color: #dce2e6;
                }
              }
            }
          }
        }
      }
    }

    .search-status {
      display: flex;
      justify-content: space-between;
      margin-bottom: 22px;

      .search {
        .el-input {
          width: 290px;
          border-radius: 5px;

          input {
            border: none;
            border-radius: 5px;
            background-color: white;
            color: #8697a8;
            font-size: 15px;
            font-weight: 400;
            line-height: 17px;
            box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
          }
        }
      }

      .status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .show {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #44566c;
          margin-right: 10px;
        }

        .el-select {
          border-radius: 8px;
          max-width: 145px;

          &:first-child {
            width: 75px;
          }

          &:last-child {
            width: 145px;
          }

          border: none;

          .el-input {
            input {
              border-radius: 5px;
              border: none;
              color: #44566c;
              background-color: white;
              font-size: 15px;
              font-weight: 400;
              line-height: 17px;
              box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }

    .pagination {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .info {
        font-size: 15px;
        font-weight: 400;
        color: #8697a8;
      }

      .el-pagination {
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
        background-color: white;
        border-radius: 8px;

        button {
          background-color: white;
          padding: 0;
          margin: 0;
          height: 100% !important;

          &.btn-prev {
            border-radius: 8px 0 0 8px;
            border-right: 1px solid #eaedf0;
          }

          &.btn-next {
            border-radius: 0 8px 8px 0;

            border-left: 1px solid #eaedf0;
          }
        }

        .el-pager {
          margin: 0 10px;
          padding: 5px;
          display: flex;
          flex-direction: row;
          gap: 5px;
          justify-content: space-between;

          li {
            padding: 0;
            margin: 0;
            background-color: white;

            &.active {
              background-color: #234A98;
              border-radius: 8px;
              box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.05);
            }
          }
        }
      }
    }
  }

  .el-input__suffix {
    .el-input__suffix-inner {
      .el-select__caret {
        background-color: rgba(255, 255, 255, 0);
      }
    }
  }

  .color-dot {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .dot {
      height: 5px;
      width: 5px;
      background-color: black; // default #ff3d57 danger #FDBF5E kritik #09B66D neew
      border-radius: 50%;
      display: inline-block;

      &.red {
        background-color: #ff3d57;
      }

      &.green {
        background-color: #09b66d;
      }

      &.yellow {
        background-color: #fdbf5e;
      }
    }
  }
}
</style>
